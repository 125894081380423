// src/url.js
let url;

if (window.location.origin === 'http://localhost:8082') {
    url = 'http://localhost:8082';
} else {
    url = 'https://frontend-casio.ipnet.co.id';
}

export default url;
