<template>
    <v-container class="px-5 py-3">
        <v-row class="align-center mb-n4">
            <v-col cols="auto">
                <v-icon @click="back" left>mdi-arrow-left</v-icon> <!-- Left arrow icon -->
            </v-col>
            <v-col class="ml-n5 label-form-custom">
                <h2 class="mb-0">{{ LabelForm }}</h2>
            </v-col>
        </v-row>
        <v-card class="mt-5 custom-rounded-card pa-4 elevation-0">
            <v-form ref="form" v-model="valid" lazy-validation>
                <v-row class="mb-4 ">
                    <v-col cols="12 mb-n1 label-input-custom">
                        <span>Batch Name</span>
                    </v-col>
                    <v-col cols="12">
                        <v-text-field v-model="items[0].name_batch" label="Batch Name"
                                      :rules="[v => {
                                        return v ? true : 'Batch name is required';
                                    }]"
                          class="small-text-field input-btn-custom custom-text-field custom-border"  solo dense single-line>
                        </v-text-field>
                    </v-col>
                </v-row>

                <!-- Date Picker with Validation -->
                <v-row class="mb-4 mt-n9">
                    <v-col cols="12 mb-n1 label-input-custom">
                        <span>Tanggal Stock</span>
                    </v-col>
                    <v-col cols="12">
<!--                        <v-menu ref="menu" v-model="menu" :close-on-content-click="false" :nudge-right="40" transition="scale-transition" offset-y min-width="290px">-->
<!--                            <template v-slot:activator="{ on, attrs }">-->
<!--                                <v-text-field v-model="items[0].date" label="Tanggal Stock" prepend-icon="mdi-calendar" readonly :rules="[v => !!v || 'Tanggal Stock is required']"-->
<!--                                              class="small-text-field input-btn-custom" solo dense single-line v-bind="attrs" v-on="on">-->
<!--                                </v-text-field>-->
<!--                            </template>-->
<!--                            <v-date-picker v-model="items[0].date" @input="menu = false"></v-date-picker>-->
<!--                        </v-menu>-->
                        <v-menu
                                ref="menu"
                                v-model="menu"
                                :close-on-content-click="false"
                                :nudge-right="40"
                                transition="scale-transition"
                                offset-y
                                min-width="290px"
                        >
                            <template v-slot:activator="{ on, attrs }">
                                <v-text-field
                                        v-model="items[0].date"
                                        label="Tanggal Stock"
                                        readonly
                                        :rules="[v => !!v || 'Tanggal Stock is required']"
                                        class="small-text-field input-btn-custom custom-text-field custom-border"
                                        solo
                                        dense
                                        single-line
                                        v-bind="attrs"
                                        v-on="on"
                                >
                                    <template v-slot:prepend-inner>
                                        <v-img
                                                @click="menu = !menu"
                                                :src=icon_calendar
                                        ></v-img>
                                    </template>
                                </v-text-field>
                            </template>
                            <v-date-picker v-model="items[0].date" @input="menu = false"></v-date-picker>
                        </v-menu>

                    </v-col>
                </v-row>

                <v-row class="mb-3 mt-n10 ">
                    <v-col cols="12 label-input-custom">
                        <span>Batch List</span>
                    </v-col>
                </v-row>
                <template>
                        <div v-for="(item, index) in items" :key="index" >
                            <v-card class="mb-2" style="border: 1px solid #F1F2F5;">
                                <v-row class="mx-1 mt-1">
                                    <v-col cols="12 mb-n1 label-input-custom">
                                        <span>Name</span>
                                    </v-col>
                                    <v-col cols="10">
                                        <v-text-field
                                                v-model="item.name"
                                                label="Name"
                                                :rules="[v => !!v || 'Name is required']"
                                                class="small-text-field input-btn-custom custom-text-field custom-border"
                                                solo
                                                dense
                                                single-line
                                        ></v-text-field>
                                    </v-col>

                                    <v-col cols="4 mt-n5">
                                        <span class="ml-1 label-input-custom">No Rekening</span>
                                        <v-text-field
                                                label="No Rekening"
                                                v-model="item.noRekening"
                                                type="number"
                                                :rules="[v => v > 0 || 'No Rekening is required']"
                                                class="small-text-field input-btn-custom custom-text-field custom-border"
                                                solo
                                                dense
                                                single-line
                                        ></v-text-field>
                                    </v-col>

                                    <v-col cols="6 mt-n5">
                                        <span class="ml-1 label-input-custom">Bank</span>
                                        <v-text-field
                                                label="Bank"
                                                v-model="item.bank"
                                                :rules="[v => !!v || 'Bank is required']"
                                                class="small-text-field input-btn-custom custom-text-field custom-border"
                                                solo
                                                dense
                                                single-line
                                        ></v-text-field>
                                    </v-col>

                                    <v-col cols="4 mt-n5 mb-3">
                                        <span class="ml-1 label-input-custom">Jumlah Kartu Hitam</span>
                                        <v-text-field
                                                label="Jumlah Kartu Hitam"
                                                v-model="item.jmlh_hitam"
                                                type="number"
                                                :rules="[v => (v !== null && v !== '' && v >= 0) || 'Jumlah Kartu Hitam is required']"
                                                class="small-text-field input-btn-custom custom-text-field custom-border"
                                                solo
                                                dense
                                                single-line
                                        >
                                            <template v-slot:append-outer >
                                                <span class="mr-3 mt-1">pcs</span>
                                            </template>
                                        </v-text-field>
                                    </v-col>

                                    <v-col cols="6 mt-n5">
                                        <span class="ml-1 label-input-custom">Jumlah Kartu Silver</span>
                                        <v-text-field
                                                label="Jumlah Kartu Silver"
                                                v-model="item.jmlh_silver"
                                                type="number"
                                                :rules="[v => (v !== null && v !== '' && v >= 0) || 'Jumlah Kartu Silver is required']"
                                                class="small-text-field input-btn-custom custom-text-field custom-border"
                                                solo
                                                dense
                                                single-line
                                        >
                                            <template v-slot:append-outer>
                                                <span class="mr-3 mt-1">pcs</span>
                                            </template>
                                        </v-text-field>
                                    </v-col>

                                    <v-col cols="1" class="d-flex align-center mt-n7 ml-n5">
                                        <v-btn icon @click="removeFile(index)">
                                            <v-img :src="icon_close_upload"></v-img>
                                        </v-btn>
                                    </v-col>
                                </v-row>
                            </v-card>
                        </div>

                        <span class="add-photo " @click="addFileInput" style="cursor: pointer; color: #1976d2;">
                            + Add Another Batch
                        </span>
                </template>

                <!-- Submit Button -->
                <v-row justify="end">
                    <v-col cols="auto">
                        <v-btn color="primary" class="btn-submit-custom" depressed @click="checkFormValidity">Submit</v-btn>
                    </v-col>
                </v-row>
            </v-form>
        </v-card>

        <!-- Confirmation Dialog -->
        <v-dialog v-model="confirmationDialog" max-width="400">
            <v-card class="elevation-0 rounded-lg">
                <v-card-title class="headline d-flex justify-center pb-2">
                    <v-icon color="primary" class="mr-2">mdi-alert-circle</v-icon>
                    Confirm Submission
                </v-card-title>

                <v-divider></v-divider>

                <v-card-text class="text-center py-4">
                    {{ confirmationDialogMessage }}
                </v-card-text>

                <v-card-actions class="d-flex justify-end px-4 pb-4">
                    <v-btn
                            color="grey lighten-1"
                            text
                            class="mr-4"
                            @click="confirmationDialog = false"
                    >
                        <v-icon left>mdi-cancel</v-icon>Cancel
                    </v-btn>
                    <v-btn
                            color="primary"
                            @click="submitForm"
                    >
                        <v-icon left>mdi-check-circle</v-icon>Yes, Submit
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <v-snackbar
                v-model="successSnackbar"
                :color="color_snackbar"
                top
                timeout="3000"
        >
            {{ pesan_snackbar }}
            <v-btn text @click="successSnackbar = false">Close</v-btn>
        </v-snackbar>
    </v-container>
</template>

<script>
    import axios from 'axios';
    import url from "../../url";
    export default {
        data () {
            return {
                icon_close_upload: `${url}/images/x.png`,
                icon_calendar: `${url}/images/calendar.png`,
                date: "",
                menu: false,
                valid: false, // Tracks form validity
                // items: [{ name_batch: [], date:"", name: [], noRekening: [], bank: [], jmlh_hitam: [], jmlh_silver: [] }], // Initialize with one file input and previews
                items: [{
                    name_batch: '',
                    date: '',
                    name: '',
                    noRekening: '',
                    bank: '',
                    jmlh_hitam: '',
                    jmlh_silver: ''
                }],
                batchid: null, // Store Kartu Hitam value
                kartuSilver: null, // Store Kartu Silver value
                successSnackbar: false,
                color_snackbar: '',
                name_batch: '',
                pesan_snackbar: '',
                confirmationDialog: false,
                confirmationDialogMessage: 'Apakah Anda yakin ingin menyimpan data?',
                LabelForm: 'Add Kertas',

                bankOptions: [
                    { name: 'Bank Mandiri', code: 'mandiri' },
                    { name: 'Bank BCA', code: 'bca' },
                    { name: 'Bank BNI', code: 'bni' },
                    { name: 'Bank BRI', code: 'bri' },
                    // Tambahkan opsi bank lainnya sesuai kebutuhan
                ],
            }
        },
        methods: {
            back() {
                this.$router.push({ name: 'kertas' }); // Replace 'add-stock' with your route name
            },
            removeFile(fileIndex) {
                if (fileIndex !== 0) {
                    this.items = this.items.filter((_, index) => index !== fileIndex);
                }
            },
            addFileInput() {
                this.items.push({ name_batch: "", date: "", name: "", noRekening: "", bank: "", jmlh_hitam: "", jmlh_silver: "" }); // Add a new file input
            },
            checkFormValidity() {
                // Check if form is valid
                this.$refs.form.validate(); // Triggers validation for all fields

                if (this.$refs.form.validate()) {
                    // Show confirmation dialog if form is valid
                    this.confirmationDialog = true;
                    if (this.$route.params.id) {
                        this.confirmationDialogMessage = 'Apakah Anda yakin ingin mengupdate data?'
                    }
                } else {
                    console.log('Form validation failed');
                }
            },

            async fetchData() {
                try {
                    const params = {
                        id: this.$route.params.id,
                    };
                    const response = await axios.post('waranty_card/detail', params); // Replace with your actual API endpoint
                    const { data } = response.data;  // Ensure you capture total from response
                    this.items = data.map(item => ({
                        id: item.id, // Assuming you want to store only the batch name
                        name_batch: item.name_batch, // Assuming you want to store only the batch name
                        date: item.start_date, // Using the start_date
                        name: item.nama, // Mapping to name
                        noRekening: item.no_rekening, // Mapping to noRekening
                        bank: item.bank, // Mapping to bank
                        jmlh_hitam: item.jmlh_hitam, // Mapping to jmlh_hitam
                        jmlh_silver: item.jmlh_silver // Mapping to jmlh_silver
                    }));
                } catch (error) {
                    console.error('Error fetching stock data:', error);
                } finally {
                    console.error('Error fetching stock:');
                }
            },

            formattedStock(id) {
                return id.replace(/\s*pcs/g, '').trim(); // Removes "pcs"
            },
            async submitForm() {
                this.confirmationDialog = false; // Close dialog

                const formData = new FormData();
                formData.append('items', this.items);


                try {
                    const url = this.$route.params.id ? '/waranty_card/update' : '/waranty_card/created'; // Define URL based on ID presence

                    const payload = this.$route.params.id ?  {
                        id: this.$route.params.id,
                        items: this.items,
                        // Include any other parameters you want to send
                    } :  {
                        items: this.items,
                        // Include any other parameters you want to send
                    }; // Define URL based on ID presence

                        const response = await axios.post(url, payload, {
                            headers: {
                                'Content-Type': 'application/json' // Specify content type
                            }
                        });
                    if (response.data.error == false) {
                        this.successSnackbar = true;
                        this.color_snackbar = 'success';
                        if (this.$route.params.id){
                            this.pesan_snackbar = response.data.message;
                        }else{
                            this.pesan_snackbar = response.data.message;
                        }
                        setTimeout(() => {
                            this.$router.push({ name: 'kertas' });
                        }, 1000); // Simulate network delay
                    }else{
                        this.successSnackbar = true;
                        this.color_snackbar = 'red';
                        if (this.$route.params.id){
                            this.pesan_snackbar = response.data.message;
                        }else{
                            this.pesan_snackbar = response.data.message;
                        }
                    }
                } catch (error) {
                    console.error('Error:', error);
                }
            },
        },
        mounted() {
            const id = this.$route.params.id;
            if (id){
                this.LabelForm = 'Update Kertas'
                this.fetchData(id)
            }
        },
    }
</script>

<style scoped>
    /* Custom styles for radio button */
    .v-input--radio {
        border-radius: 50%;
    }

    .v-input--radio input:checked + .v-input__control .v-input__slot {
        border-color: #1976d2; /* Change the border color when checked */
    }

    .v-input--radio input:checked + .v-input__control .v-input__slot::after {
        content: '✓'; /* Show a check mark */
        font-size: 14px; /* Adjust size as needed */
        color: #fff; /* Check mark color */
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }
    .small-text-field {
        border-radius: 8px;
    }

    .small-text-field:focus {
        box-shadow: none; /* Remove shadow on focus */
    }
</style>
