<template>
    <v-app>
        <v-container fluid class="signup-background">
            <v-row class="fill-height d-flex justify-center align-center">
                <v-col cols="12" sm="10" md="4">
                    <v-card class="elevation-12 signup-card pa-3">
                        <v-card-title class="text-center display-2">Create Account</v-card-title>
                        <v-card-subtitle class="text-center mb-4 grey--text">
                            Join us and start your journey.
                        </v-card-subtitle>

                        <v-form v-model="valid" lazy-validation>
                            <v-text-field
                                    v-model="name"
                                    label="Full Name"
                                    :rules="nameRules"
                                    outlined
                                    dense
                                    required
                                    class="mb-3"
                            ></v-text-field>

                            <v-text-field
                                    v-model="email"
                                    label="Email"
                                    :rules="emailRules"
                                    prepend-inner-icon="mdi-email"
                                    outlined
                                    dense
                                    required
                                    class="mb-3"
                            ></v-text-field>

                            <v-text-field
                                    v-model="password"
                                    label="Password"
                                    :rules="passwordRules"
                                    prepend-inner-icon="mdi-lock"
                                    type="password"
                                    outlined
                                    dense
                                    required
                                    class="mb-3"
                            ></v-text-field>

                            <v-text-field
                                    v-model="confirmPassword"
                                    label="Confirm Password"
                                    :rules="confirmPasswordRules"
                                    prepend-inner-icon="mdi-lock"
                                    type="password"
                                    outlined
                                    dense
                                    required
                                    class="mb-3"
                            ></v-text-field>

                            <v-btn
                                    :disabled="!valid"
                                    color="primary"
                                    block
                                    @click="register"
                            >
                                Sign Up
                            </v-btn>

                            <v-alert v-if="error" type="error" dismissible class="mt-3">
                                {{ errorMessage }}
                            </v-alert>
                        </v-form>

                        <v-divider class="my-3"></v-divider>

                        <v-card-subtitle class="text-center mt-4">
                            <small>
                                Already have an account?
                                <v-btn text small color="primary" @click="goToLogin">
                                    Log in
                                </v-btn>
                            </small>
                        </v-card-subtitle>
                    </v-card>
                </v-col>
            </v-row>
        </v-container>
    </v-app>
</template>

<script>
    import axios from '/src/axios';

    export default {
        data() {
            return {
                valid: false,
                name: '',
                email: '',
                password: '',
                confirmPassword: '',
                error: false,
                errorMessage: '',
                nameRules: [
                    v => !!v || 'Name is required',
                ],
                emailRules: [
                    v => !!v || 'Email is required',
                    v => /.+@.+\..+/.test(v) || 'E-mail must be valid',
                ],
                passwordRules: [v => !!v || 'Password is required'],
                confirmPasswordRules: [
                    v => !!v || 'Confirm password is required',
                    v => v === this.password || 'Passwords must match',
                ],
            };
        },
        methods: {
            register() {
                axios.post('register', {
                    name: this.name,
                    email: this.email,
                    password: this.password,
                })
                    .then(response => {
                        // Check if registration was successful
                        if (response.data.status !== 'error') {
                            this.$router.push('/'); // Redirect to login page
                        } else {
                            this.error = true; // Set error state
                            this.errorMessage = response.data.message; // General error message

                            // Collect detailed error messages if they exist
                            if (response.data.errors) {
                                const errorMessages = [];
                                for (const messages of Object.values(response.data.errors)) {
                                    errorMessages.push(...messages); // Spread to collect all messages
                                }
                                this.errorMessage += ' ' + errorMessages.join(' '); // Combine messages
                            }
                        }
                    })
                    .catch(error => {
                        // Handle 422 Unprocessable Content error
                        if (error.response && error.response.status === 422) {
                            this.error = true; // Set error state
                            this.errorMessage = 'Data yang dimasukkan tidak valid.'; // General message

                            // Extract detailed validation messages
                            if (error.response.data.errors) {
                                const errorMessages = [];
                                for (const messages of Object.values(error.response.data.errors)) {
                                    errorMessages.push(...messages); // Collect all error messages
                                }
                                this.errorMessage += ' ' + errorMessages.join(' '); // Append messages
                            }
                        } else {
                            // Handle other unexpected errors
                            this.error = true;
                            this.errorMessage = 'An error occurred during registration.';
                        }
                        console.error(error.response);
                    });
            },
            goToLogin() {
                this.$router.push('/');
            },
        },
    };
</script>

<style scoped>
    .signup-background {
        background-image: url('https://source.unsplash.com/1600x900/?abstract,colors');
        background-size: cover;
        background-position: center;
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100vh;
    }

    .signup-background::before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: #E5E5E5 !important; /* Background color */
        z-index: 1;
    }

    .signup-card {
        z-index: 2;
        background-color: rgba(255, 255, 255, 0.9);
        border-radius: 16px;
        box-shadow: 0 10px 50px rgba(0, 0, 0, 0.3);
        transition: transform 0.3s, box-shadow 0.3s;
    }

    .signup-card:hover {
        transform: translateY(-8px);
        box-shadow: 0 20px 80px rgba(0, 0, 0, 0.4);
    }

    .v-card-title {
        font-weight: 700;
        text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.2);
    }

    .v-card-subtitle {
        font-size: 16px;
        font-weight: 400;
    }
</style>
