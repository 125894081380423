<template>
    <v-app>
        <v-container fluid class="login-background">
            <v-row class="fill-height d-flex justify-center align-center">
                <v-col cols="12" sm="12" md="5">
                    <v-card class="elevation-12 login-card pa-3">
                        <v-card-title class="text-center display-2">Welcome Back</v-card-title>
                        <v-card-subtitle class="text-center mb-4 grey--text">
                            Please sign in to continue.
                        </v-card-subtitle>

                        <v-form v-model="valid" lazy-validation>
                            <v-text-field
                                    v-model="email"
                                    label="Email"
                                    :rules="emailRules"
                                    prepend-inner-icon="mdi-email"
                                    outlined
                                    dense
                                    required
                                    class="mb-3"
                            ></v-text-field>

                            <v-text-field
                                    v-model="password"
                                    label="Password"
                                    :rules="passwordRules"
                                    prepend-inner-icon="mdi-lock"
                                    type="password"
                                    outlined
                                    dense
                                    required
                                    class="mb-3"
                            ></v-text-field>

                            <div class="text-right mb-3">
                                <v-btn text small color="primary" @click="forgotPassword">
                                    Forgot password?
                                </v-btn>
                            </div>

                            <v-btn
                                    :disabled="!valid"
                                    color="primary"
                                    class="login-btn"
                                    block
                                    @click="login"
                            >
                                Login
                            </v-btn>

                            <v-alert v-if="error" type="error" dismissible class="mt-3">
                                {{ errorMessage }}
                            </v-alert>
                        </v-form>

                        <v-divider class="my-3"></v-divider>

                        <v-card-subtitle class="text-center mt-4">
                            <small>
                                Don't have an account?
                                <v-btn text small color="primary" @click="goToSignup">
                                    Sign up
                                </v-btn>
                            </small>
                        </v-card-subtitle>
                    </v-card>
                </v-col>
            </v-row>
        </v-container>
    </v-app>
</template>

<script>
    import axios from '/src/axios';
    import { setAuthorizationToken } from '/src/axios';

    export default {
        data() {
            return {
                valid: false,
                email: '',
                password: '',
                error: false,
                errorMessage: '',
                emailRules: [
                    v => !!v || 'Email is required',
                    v => /.+@.+\..+/.test(v) || 'E-mail must be valid',
                ],
                passwordRules: [v => !!v || 'Password is required'],
            };
        },
        methods: {
            login() {
                axios.post('login', {
                    email: this.email,
                    password: this.password,
                })
                    .then((response) => {
                        if (!response.data.error) {
                            const { token, name, email } = response.data;
                            setAuthorizationToken(token);
                            localStorage.setItem('token', token);
                            localStorage.setItem('name', name);
                            localStorage.setItem('email', email);
                            this.$router.push({ name: 'dashboard' }); // Replace with your route name

                        } else {
                            this.error = true;
                            this.errorMessage = 'Invalid email or password';
                        }
                    })
                    .catch((error) => {
                        this.error = true;
                        this.errorMessage = 'Invalid email or password';
                        console.error(error.response);
                    });
            },
            goToSignup() {
                this.$router.push('/register');
            },
            forgotPassword() {
                // Logic for forgot password
            },
        },
    };
</script>

<style scoped>
    .login-background {
        background-image: url('https://source.unsplash.com/1600x900/?nature,water');
        background-size: cover;
        background-position: center;
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100vh;
    }

    .login-background::before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: #F5F8FF !important; /* Background color */
        z-index: 1;
    }

    .login-card {
        z-index: 2;
        background-color: rgba(255, 255, 255, 0.9);
        border-radius: 16px;
        box-shadow: 0 10px 50px rgba(0, 0, 0, 0.3); /* Enhanced shadow for depth */
        transition: transform 0.3s, box-shadow 0.3s; /* Animation for hover effect */
    }

    .login-card:hover {
        transform: translateY(-8px); /* Stronger lift effect on hover */
        box-shadow: 0 20px 80px rgba(0, 0, 0, 0.4); /* Stronger shadow on hover */
    }

    .v-card-title {
        font-weight: 700;
        text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.2); /* Subtle shadow for text */
    }

    .v-card-subtitle {
        font-size: 16px;
        font-weight: 400;
    }

    .login-btn {
        box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
        transition: all 0.3s ease; /* Button transition */
    }

    .login-btn:hover {
        transform: scale(1.05); /* Slightly enlarge on hover */
        box-shadow: 0 6px 15px rgba(0, 0, 0, 0.3);
    }
</style>
