<template>
    <v-container class="px-5 py-3">
        <v-row class="align-center mb-n4">
            <v-col cols="auto">
                <v-icon @click="back" left>mdi-arrow-left</v-icon> <!-- Left arrow icon -->
            </v-col>
            <v-col class="ml-n5 label-form-custom">
                <h2 class="mb-0">Stock Detail</h2>
            </v-col>
        </v-row>
        <v-card class="mt-5 custom-rounded-card pa-4">
            <v-row>
                <!-- Left Column for Stock ID and Source/Destination -->
                <v-col cols="6">
                    <div class="body-1 mb-2 detail-lable-custom">Stock ID</div>
                    <div class="subtitle-1 mb-4 detail-value-lable-custom">{{ stockDetail.order_code }}</div>

                    <div class="body-1 mb-2 detail-lable-custom">Source/Destination</div>
                    <div class="subtitle-1 mb-4 detail-value-lable-custom">{{ stockDetail.source_destination }}</div>
                </v-col>

                <!-- Right Column for Date and Type Stock -->
                <v-col cols="6">
                    <div class="body-1 mb-2 detail-lable-custom">Tanggal</div>
                    <div class="subtitle-1 mb-4 detail-value-lable-custom">{{ stockDetail.tanggal }}</div>

                    <div class="body-1 mb-2 detail-lable-custom">Type Stock</div>
                    <div class="subtitle-1 mb-2"  :class="{ 'text-green': stockDetail.type_stock === 'Masuk', 'text-red': stockDetail.type_stock === 'Keluar' }">
                        <v-icon color="green" small v-if="stockDetail.type_stock === 'Masuk'">mdi-circle</v-icon>
                        <v-icon color="red" small v-if="stockDetail.type_stock === 'Keluar'">mdi-circle</v-icon>
                        {{ stockDetail.type_stock }}
                    </div>
                </v-col>
            </v-row>

            <v-row class="mt-n6">
                <!-- Kartu Hitam -->
                <v-col cols="6">
                    <div class="body-1 mb-2 detail-lable-custom">Kartu Hitam</div>
                    <div class="subtitle-1 detail-value-lable-custom">{{ stockDetail.jmlh_hitam }}</div>
                </v-col>

                <!-- Kartu Silver -->
                <v-col cols="6">
                    <div class="body-1 mb-2 detail-lable-custom">Kartu Silver</div>
                    <div class="subtitle-1 detail-value-lable-custom">{{ stockDetail.jmlh_silver }}</div>
                </v-col>
            </v-row>

            <v-row>
                <v-col cols="12">
                    <div class="subtitle-1 mb-2 detail-lable-custom">Bukti Photo</div>
                    <v-row>
                        <v-col v-for="(image, index) in imageUrls" :key="index" cols="6">
                            <v-img
                                    :src="image"
                                    height="100%"
                                    width="90%"
                                    alt="Bukti Photo"
                                    aspect-ratio="1.5"
                                    class="grey lighten-2 foto-custom"
                            ></v-img>
                        </v-col>
                    </v-row>
                </v-col>
            </v-row>
        </v-card>
    </v-container>
</template>

<script>
    import axios from 'axios';
    import url from  './../../url'
    export default {
        data() {
            return {
                stockDetail: {}, // Holds the stock details after fetching
                imageUrls: [] // Holds the complete image URLs as an array
            };
        },
        created() {
            this.fetchStockDetail();
        },
        methods: {
            back() {
                this.$router.push({ name: 'delivery' });
            },
            async fetchStockDetail() {
                try {
                    const params = {
                        id: this.$route.params.id,
                    };
                    const response = await axios.post('stock/list', params); // Replace with your actual API endpoint
                    this.stockDetail = response.data.data[0]; // Assign the fetched data to stockDetail
                    // Handle file_path
                    let filePath = this.stockDetail.file_path;

                    // Check if filePath is a string and try to parse it
                    if (typeof filePath === 'string') {
                        try {
                            filePath = JSON.parse(filePath); // Parse the string to get an array
                        } catch (error) {
                            console.error('Failed to parse file_path:', error);
                            filePath = []; // Fallback to an empty array
                        }
                    }

                    // If it's an array (either initially or converted), create the image URLs
                    if (Array.isArray(filePath)) {
                        this.imageUrls = filePath.map((path) =>
                            `${url}/${path.replace(/\\/g, '')}`  // Use template literals for string interpolation
                        );
                    }
                    console.log('jrg', this.imageUrls);
                } catch (error) {
                    console.error('Failed to fetch stock detail:', error);
                }
            }
        }
    };
</script>

<style scoped>
    .subtitle-1 {
        font-weight: bold;
        color: #424242;
    }

    .body-1 {
        color: #424242;
    }
    .text-red {
        color: red;
    }

    .text-green {
        color: green;
    }
</style>
