<template>
    <v-card elevation="0">
        <v-container >
            <v-row align="center" justify="center">
                <v-col cols="12" md="6" class="d-flex justify-center align-center">
                    <h4 class="BackStock mr-3 clickable" @click="back">Kembali ke halaman stock detail</h4>
                    <v-btn color="primary" class="elevation-0 btn-top-table" @click="print">Cetak Surat</v-btn>
                </v-col>
            </v-row>

            <v-divider class="my-2"></v-divider>

            <div id="capture">
                <v-row justify="center" class="mt-6">
                    <v-col cols="12" md="6" class="d-flex justify-center align-center">
                        <div>
                            <h4 class="name_ipnet">IP Net x PT Gilang Agung Persada</h4>
                            <p class="mb-0 alamat_ipnet">J. Majapahit no.26 R</p>
                        </div>
                    </v-col>

                    <v-col cols="12" md="6" class="d-flex justify-center align-center">
<!--                        <v-img :src="logo_ipnet" contain class="large-logo"></v-img>-->
                        <img :src="logo_ipnet" class="large-logo" alt="Logo IP Net" />
                    </v-col>
                </v-row>

                <v-row justify="center">
                    <v-col cols="12" md="2" class="d-flex justify-center align-center mr-n16">
                        <div class="mb-4">
                            <p class="mb-0 header-print">Bill To :</p>
                            <p class="mb-0 header-print">{{Bill}}</p>
                        </div>
                    </v-col>

                    <v-col cols="12" md="4" class="d-flex justify-center align-center">
                        <div class="mb-4" >
                            <p class="mb-0 header-print">Batch :</p>
                            <p class="mb-0 header-print">{{batch}}</p>
                        </div>
                    </v-col>

                    <v-col cols="12" md="4" class="d-flex justify-center align-center">
                        <div class="mb-4" >
                            <p class="mb-0 header-print">Receipt Date :</p>
                            <p class="mb-0 header-print">12 Sep 2024</p>
                        </div>
                    </v-col>
                </v-row>

                <v-row justify="center" class="ml-n16">
                    <v-col cols="8">
                        <table class="custom-table">
                            <thead>
                            <tr>
                                <th style="text-align: center">NO</th>
                                <th style="width: 60%;">NAMA</th>
                                <th style="text-align: center">JUMLAH KARTU</th>
                                <th>AMOUNT</th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr v-for="(item,index) in items" :key="item.no">
                                <td class="td-custom" style="text-align: center">{{ index +1  }}</td>
                                <td class="td-custom" >{{ item.nama }}</td>
                                <td style="text-align: center">{{ item.jumlah_kartu }}</td>
                                <td>{{ item.amount }}</td>
                            </tr>
                            </tbody>
                            <tfoot>
                            <tr class="td-custom">
                                <td colspan="2" style="text-align: right;">TOTAL</td>
                                <td style="text-align: center">{{ total_kartu }} pcs</td>
                                <td>{{ total_amount }}</td>
                            </tr>
                            </tfoot>
                        </table>
                    </v-col>
                </v-row>
            </div>
        </v-container>
    </v-card>
</template>

<script>
    import axios from 'axios'; // Import Axios
    import html2canvas from 'html2canvas';
    import url_images from "../../url_images_print";

    export default {
        data() {
            return {
                logo_ipnet: `${url_images}/images/logo_ipnet.png`,
                items: [], // Initialize empty items array
                total_kartu: '', // Initialize empty items array
                total_amount: '',
                Bill: '',
                batch: '',
            };
        },
        computed: {
            totalQuantity() {
                return this.items.reduce((sum, item) => sum + item.jumlah_kartu, 0);
            },
            totalAmount() {
                return this.items.reduce((sum, item) => sum + item.amount, 0);
            },
        },
        methods: {
            async fetchLogo() {
                try {
                    const response = await axios.get(`${url_images}/images/logo_ipnet.png`, { responseType: 'blob' });
                    const reader = new FileReader();
                    reader.readAsDataURL(response.data);
                    reader.onloadend = () => {
                        this.logo_ipnet = reader.result; // Store Base64 string in logo_ipnet
                    };
                } catch (error) {
                    console.error('Error fetching logo:', error);
                }
            },
            back() {
                this.$router.push({ path: `/Detailkertas/${this.$route.params.id}` });
            },
            print() {
                this.$nextTick(() => {
                    const printContent = document.getElementById('capture');
                    const imgElement = document.querySelector('.large-logo');

                    if (imgElement) {
                        // Ensure the image is fully loaded before generating the canvas
                        imgElement.onload = () => {
                                html2canvas(printContent).then(canvas => {
                                    const imgData = canvas.toDataURL('image/png');
                                    const printWindow = window.open('', '_blank');
                                    printWindow.document.write(`<html><head><title>Print</title>`);
                                    printWindow.document.write(`<style>@media print { body { margin: 0; } }</style>`);
                                    printWindow.document.write(`</head><body><img src="${imgData}" /></body></html>`);
                                    printWindow.document.close();
                                    setTimeout(() => {
                                        printWindow.print();
                                    }, 100); // Add a small delay before capturing
                                });

                        };

                        // Manually trigger the image load event in case it's cached
                        if (imgElement.complete) {
                            imgElement.onload();
                        }
                    } else {
                        console.error('Image element not found.');
                    }
                });
            },
            formatCurrency(amount) {
                return new Intl.NumberFormat('id-ID', {
                    style: 'currency',
                    currency: 'IDR',
                }).format(amount);
            },
            async fetchItems() {
                const params = {
                    id: this.$route.params.id,
                };
                const response = await axios.post('waranty_card/print/detail', params); // Replace with your actual API endpoint

                if (response.data.error === false){
                    this.items = response.data.data.items; // Update items with fetched data
                    this.total_kartu = response.data.data.totalKartu; // Update items with fetched data
                    this.total_amount = response.data.data.totalAmount; // Update items with fetched data
                    this.batch = response.data.data.batch; // Update items with fetched data
                    this.Bill = response.data.data.billTo; // Update items with fetched data
                }
            },
            async convertToBase64() {
                try {
                    const response = await fetch(`${url_images}/images/logo_ipnet.png`,);
                    const blob = await response.blob();

                    const reader = new FileReader();
                    reader.readAsDataURL(blob);
                    reader.onloadend = () => {
                        this.logo_ipnet = reader.result; // The Base64 string is stored in logo_ipnet
                    };
                } catch (error) {
                    console.error('Error converting image to Base64:', error);
                }
            }
        },
        mounted() {
            this.convertToBase64();
            this.fetchLogo();
            this.fetchItems(); // Call fetchItems on component mount
        },
    };
</script>

<style scoped>
    .custom-table {
        width: 100%;
        border-collapse: collapse;
        font-family: 'Google Sans', sans-serif;
        font-size: 12px;
    }

    .td-custom {
        font-family: 'Google Sans', sans-serif;
        font-size: 12px;
        font-weight: bold;
        color: #283442;
        padding-top: 12px;
        padding-bottom: 12px;

    }

    .custom-table th {
        border-top: 2px solid black;
        border-bottom: 2px solid black;
        text-align: left;
        padding: 8px;
    }

    .custom-table tbody tr td {
        border-bottom: 1px solid #d3d3d3;
    }

    .custom-table tbody tr:last-child td {
        border-bottom: none;
    }

    .large-logo {
        height: 64px;
        width: 45px;
    }

    h1 {
        font-size: 26px;
        margin-bottom: 4px;
    }

    h3 {
        font-size: 20px;
    }

    .mb-0 {
        margin-bottom: 0;
    }

    .my-4 {
        margin: 16px 0;
    }

    .screenshot-area {
        background-color: #ffffff;
        padding: 16px;
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    }
</style>
