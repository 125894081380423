<template>
    <v-app >
        <!-- Top App Bar with Menu Toggle Button -->
        <v-app-bar app class="app-bar-custom" style="border-bottom: 1px solid #ddd;" v-if="$route.path !== '/' && $route.path !== '/register' && $route.name !== 'PrintDetailkertas'">
            <v-btn icon @click="drawer = !drawer">
                <img :src="icon_menu" />
            </v-btn>
            <v-toolbar-title class="ml-n5" >
                <span class="title-headers-custom">{{ menu_title[0] }}</span>
                <template v-if="menu_title.length > 1">
                    <img :src="icon_chevron_right" alt="Arrow" class="title-arrow-icon" />
                    <span class="title-after-headers-custom ml-1">{{ menu_title[1] }}</span>
                </template>
            </v-toolbar-title>
            <v-spacer></v-spacer>

            <!-- Place icons inside v-btns for better usability -->
            <v-btn class="mr-n4" icon>
                <img :src="icon_bell" alt="Bell Icon" />
            </v-btn>
            <v-btn icon>
                <img :src="icon_help" alt="Help Icon" />
            </v-btn>
        </v-app-bar>

        <!-- Sidebar Navigation (Visible by Default) -->
        <v-navigation-drawer
                v-if="$route.path !== '/' && $route.path !== '/register' && $route.name !== 'PrintDetailkertas'"
                v-model="drawer"
                app
               class="nav-custom"
        >
            <!-- Logo -->
            <v-list-item>
                <v-list-item-content>
                    <v-img
                            :src="logo"
                            contain
                            height="30"
                            class="ml-n8"
                    ></v-img>
                </v-list-item-content>
            </v-list-item>

            <!-- Main Navigation Links -->
            <v-list dense>
                <v-list-item-group>
                    <v-list-item @click="$router.push('/dashboard')">
                        <v-list-item-icon>
                            <img :src=icon_dashboard>
                        </v-list-item-icon>
                        <v-list-item-content>
                            <v-list-item-title class="white--text title-menu-dashboard-custom">Dashboard</v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>

                    <v-list-item @click="$router.push('/delivery')">
                        <v-list-item-icon>
                            <img :src=icon_delivery />
                        </v-list-item-icon>
                        <v-list-item-content>
                            <v-list-item-title class="white--text title-menu-dashboard-custom">Delivery</v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>

                    <v-list-item @click="$router.push('/kertas')">
                        <v-list-item-icon>
                            <img :src=icon_kertas />
                        </v-list-item-icon>
                        <v-list-item-content>
                            <v-list-item-title class="white--text title-menu-dashboard-custom">Kertas</v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>
                </v-list-item-group>
            </v-list>

            <!-- Spacer pushes the logout section to the bottom -->
            <v-spacer></v-spacer>

            <!-- Logout Section -->
            <div class="logout-section">
                <v-list-item>
                    <v-list-item-avatar>
                        <v-avatar style="background-color: #00309C; color: white;">
                            <div class="text-center">
                                <span style="color: #FFFFFF;">{{ initials }}</span>
                            </div>
                        </v-avatar>
                    </v-list-item-avatar>

                    <v-list-item-content>
                        <v-list-item-title class="title-menu-dashboard-name-custom">{{ name }}</v-list-item-title>
                        <v-list-item-subtitle class="title-menu-dashboard-email-custom">{{ email }}</v-list-item-subtitle>
                    </v-list-item-content>

                    <v-menu offset-y>
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn icon v-bind="attrs" v-on="on">
                                <v-icon class="white--text">mdi-chevron-down</v-icon>
                            </v-btn>
                        </template>
                        <v-list>
                            <v-list-item @click="logout">
                                <v-list-item-title style="font-family: 'Geomanist'">Logout</v-list-item-title>
                            </v-list-item>
                        </v-list>
                    </v-menu>
                </v-list-item>
            </div>

        </v-navigation-drawer>

        <!-- Main Content -->
        <v-main  :class="{ 'content-area mt-n2': $route.name !== 'PrintDetailkertas' }">
            <v-container >
                <router-view  class="mb-15"/>
            </v-container>

            <!-- Footer -->
            <v-footer
                    v-if="$route.path !== '/' && $route.path !== '/register' && $route.name !== 'PrintDetailkertas'"
                    class="footer-custom"
            >
                <v-col class="title-footer-custom text-left">
                    <span>&copy; {{ currentYear }} Casio. Powered by IP Network Solusindo</span>
                </v-col>
            </v-footer>
        </v-main>
    </v-app>
</template>

<script>
    import axios from 'axios';
    import url from  './url'
    import { setAuthorizationToken } from '/src/axios';

    export default {
        name: 'App',
        created() {
            // console.log('jrg',this.icon_menu)
            // console.log('jrg 1',window.location.origin)
        },
        data() {
            return {
                logo:`${url}/logo/logo_company.png`,
                icon_chevron_right:`${url}/images/chevron-down.svg`,
                icon_delivery:`${url}/images/ic-delivery.png`,
                icon_dashboard:`${url}/images/ic-dashboard.png`,
                icon_kertas:`${url}/images/ic-kertas.png`,
                icon_menu:`${url}/images/menu.png`,
                icon_help:`${url}/images/help-circle.png`,
                icon_bell:`${url}/images/bell.png`,
                name: localStorage.getItem('name') ? localStorage.getItem('name') : null,
                email: localStorage.getItem('email') ? localStorage.getItem('email') : null,
                drawer: true,  // Sidebar is open by default
                currentYear: new Date().getFullYear(), // Current year

            };
        },
        computed: {
            initials() {
                // Split the name into parts and get the first letter of each part
                return this.name
                    .split(' ')
                    .map(part => part[0].toUpperCase())
                    .join('');
            },
            menu_title(){
                const routeName = this.$route.name;
                if (routeName === 'dashboard') return ['Dashboard'];
                if (routeName === 'delivery') return ['Stock Management'];
                if (routeName === 'AddDelivery') return ['Stock Management', 'Add New Stock'];
                if (routeName === 'UpdateDelivery') return ['Stock Management', 'Update Stock'];
                if (routeName === 'DetailDeliver') return ['Stock Management', 'Detail Stock'];
                if (routeName === 'kertas') return ['Kertas'];
                if (routeName === 'Addkertas') return ['Kertas', 'Add Kertas'];
                if (routeName === 'Updatekertas') return ['Kertas', 'Update Kertas'];
                if (routeName === 'Detailkertas') return ['Kertas', 'Detail Kertas'];
                if (routeName === 'PrintDetailkertas') return ['Kertas', 'Print Detail Kertas'];
                return ['App'];
            }
        },
        mounted() {
            const token = localStorage.getItem('token');
            if (token) {
                setAuthorizationToken(token);
            } else {
                this.$router.push({ name: 'login' }); // Replace with your route name
            }
        },
        methods: {
            async logout() {
                try {
                    // Send a logout request to the server
                    await axios.post('/logout', {}, {
                        headers: {
                            Authorization: `Bearer ${localStorage.getItem('token')}`,
                        },
                    });

                    // Clear the token and user info from localStorage
                    localStorage.removeItem('token');
                    localStorage.removeItem('name');
                    localStorage.removeItem('email');

                    // Redirect to login page
                    this.$router.push('/');
                } catch (error) {
                    console.error("Logout failed:", error);
                    // Handle logout error (optional)
                }
            },
        },
    };
</script>
<style scoped>
    /* Add your styles here */
    .footer-custom {
        font-family: 'Google Sans'; /* Use Google Sans font */
        position: absolute;
        bottom: 0;
        opacity: 0.4;     /* 40% opacity */
        background-color: #E5E5E5;
        height: 60px; /* Adjust footer height as needed */
        display: flex;
        align-items: center; /* Center content vertically */
        font-weight: 400; /* Regular font weight */
    }

    .content-area {
        background-color: #F5F8FF;
        flex-grow: 1; /* Allow the main content area to grow */
    }
    .solid-menu .v-menu__content {
        background-color: white !important; /* Solid white background */
        box-shadow: none !important; /* Remove shadow if needed */
    }
    @font-face {
        font-family: 'Geomanist';
        src: url('./assets/fonts/Geomanist.woff2') format('woff2'),
        url('./assets/fonts/Geomanist.woff') format('woff'),
        url('./assets/fonts/Geomanist.ttf') format('truetype');
        /*font-weight: 400;*/
        font-style: normal;
    }
</style>
<style>
    .custom-border {
        border: 1px solid rgba(0, 0, 0, 0.12) !important; /* Use !important to override Vuetify styles */
        height: 67px; /* Adjust the height as needed */
        display: flex; /* Ensure the content is centered */
        align-items: center; /* Vertically center the content */
    }
    .date_custom{
        background-color: white !important; /* Solid color for all buttons */
        color: white !important; /* Ensure text is readable */
        box-shadow: none !important; /* Remove any shadow */
        border: none !important; /* Remove border if required */
    }
    .app-bar-custom{
        background-color: #F5F8FF !important; /* Background color */
    }
    .nav-custom{
        background-color: #00309C !important; /* Background color */
    }
    .logout-section {
        margin-left: 5px;
        position: absolute;
        bottom: 2%;
        width: 95%;
        border-radius: 7px ;
        background-color: rgba(255, 255, 255, 0.1); /* White background with 10% opacity */
        border: 1px solid rgba(240, 245, 248, 0.1); /* Border color with 10% opacity */
    }
    .header-print{
        font-family: 'Google Sans'; /* Use Google Sans font */
        font-weight: bold;                       /* Set font weight to bold */
        font-size: 12px;                        /* Set font size to 12px */
        color: #283442;
    }
    .name_ipnet{
        font-family: 'Google Sans'; /* Use Google Sans font */
        font-weight: bold;                      /* Set font weight to bold */
        font-size: 16px;                       /* Set font size to 16px */
        color: #283442;                        /* Set text color to #283442 */
    }
    .alamat_ipnet{
        font-family: 'Google Sans', sans-serif; /* Use Google Sans font */
        font-weight: normal;                     /* Set font weight to regular */
        font-size: 14px;                        /* Set font size to 16px */
        color: #9A9A9A;                         /* Set text color to #283442 */
    }
    .clickable {
        cursor: pointer; /* Change cursor to pointer */
    }
    .BackStock{
        font-family: 'Sora', sans-serif;
        font-size: 14px;
        color: #A5A6AD;
    }
    .v-input--elevated,
    .v-input--text-field,
    .v-input--textarea,
    .v-input--radio {
        box-shadow: none !important; /* Remove shadow */
        elevation: 0; /* Ensure elevation is set to 0 */
    }
    body * {
        box-shadow: none !important;
    }
    .v-card {
        border-radius: 8px !important;
    }
    .v-radio-group{
        box-shadow: none !important;
    }
    .v-input__control {
         background-color: white;
    }
    /* Optional: If you want to apply specific styles to the text fields and text areas */
    .v-input--text-field .v-input__control,
    .v-input--textarea .v-input__control {
        border: 2px solid #ddd; /* Example border */
        background-color: #fff; /* Background color */
    }
    input, textarea, .v-input__control, .v-text-field {
        /*border: 1px solid #F1F2F5; !* Atur warna border sesuai preferensi *!*/
        /*border-radius: 4px; !* Atur radius jika perlu *!*/
    }
    .v-input--is-outlined .v-input__control {
        /*border-color: blue !important; !* Warna outline khusus *!*/
    }
    .v-radio {
        border: none; /* Pastikan tidak ada perubahan border di sini */
    }
    .v-app {
        display: flex;
        flex-direction: column;
        min-height: 100vh;
    }

    .footer-custom {
        background-color: #f5f5f5;
        padding: 10px 0;
        position: relative;
        bottom: 0;
        width: 100%;
        text-align: left;
        font-family: 'Google Sans';
        font-size: 12px;
        line-height: 1.4;
    }

    /* Footer text styling */
    .title-footer-custom {
        font-family: 'Google Sans';
        font-size: 12px;
        color: #565656;
        opacity: 50px;
    }
    .v-navigation-drawer {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }
    .chart-container {
        font-family: 'Robot'; /* Global font for the chart container */
    }
    .title-arrow-icon {
        height: 9px;  /* Adjust the height according to the design */
        width: auto;   /* Keep the image aspect ratio */
        margin-left: 5px;  /* Space between the first part and the arrow image */
    }
    .v-list-item {
        padding-bottom: 10px;
    }

    .title-chart-custom{
        font-family: 'Sora', sans-serif;;
        font-size: 15px;
        font-weight: bold; /* Make the text bold */
    }
    .title-menu-dashboard-custom{
        font-family: 'Sora';
        font-size: 15px;
        font-weight: bold; /* Make the text bold */
        color: #283442;
    }
    .title-menu-dashboard-name-custom{
        font-family: 'Geomanist' !important;
        font-weight: 500 !important; /* Medium font weight */
        font-size: 12px !important;
        color: #FFFFFF !important;
    }
    .title-menu-dashboard-email-custom{
        font-family: 'Geomanist' !important;
        font-size: 10px !important;
        font-weight: 400 !important; /* Regular font weight */
        opacity: 0.4 !important;     /* 40% opacity */
        color: #FFFFFF !important;
    }
    .title-headers-custom{
        font-family: 'Geomanist' !important;
        font-weight: 400; /* Book weight */
        font-size: 14px;  /* Adjust the font size */
        color: #343434;
        opacity: 0.5;
    }
    .title-after-headers-custom {
        font-family: 'Geomanist' !important;
        font-size: 14px;
        font-weight: 400;
        color: #56565 ;
        opacity: 0.4;
    }
    .btn-top-table{
        background: #3867D3;
        border-radius: 8px 8px 8px 8px;  /* Border radius for rounded top-left corner */
    }
    .title-custom {
        font-family: 'Sora';
        font-size: 24px;
        font-weight: 700;
        line-height: 30.24px;
        text-align: left;
        color: #080808;
    }
    .title-dashboard-custom {
        font-family: 'Sora';
        font-size: 24px;
        font-weight: 700;
        line-height: 30.24px;
        text-align: left;
        color: #080808;
    }
    .title-kartu-custom{
        font-family: 'Sora';
        font-size: 14px;
        text-align: left;
        color: #828282;
    }
    .detail-title-kartu-custom{
        font-family: 'Sora';
        font-size: 26px;
        text-align: left;
        color: #1A1A1A;
    }
    .titlepage{
        margin-left: 2px;
        /*width: 1137px;*/
        /*height: 40px;*/
        /*top: 79px;*/
        gap: 0px;
        border-radius: 8px 8px 8px 8px;
    }
    .search_id{
        border: 1px solid #F1F2F5;
        font-family: 'Sora';
        font-size: 12px;
    }

    .search_date{
        font-family: 'Sora';
        font-size: 12px;

        /*width: 166px;*/
        /*height: 36px;*/
        /*top: 159px;*/
        border: 1px solid #F1F2F5;
    }
    .filter_button{
        width: 81px;
        height: 36px;
        padding: 12px 24px;
        gap: 10px;
        border-radius: 8px 8px 8px 8px;
        background-color: #3867D3;
    }
    .clear_button{
        /*width: 82px; !* Fixed width *!*/
        /*height: 36px; !* Fixed height *!*/
        /*position: absolute; !* Absolute positioning *!*/
        /*left: 740px; !* Left position *!*/
        /*padding: 12px 24px; !* Padding *!*/
        gap: 10px; /* Gap (not applied directly) */
        border-radius: 8px 8px 8px 8px; /* Border radius */
        background-color: #FFFFFF; /* Background color */
        background: #FFFFFF;
        border: 1px solid #3867D3 !important;
    }
    .text-export-csv {
        font-family: 'Sora';
        font-size: 14px;
        font-weight: 600;
        line-height: 16.8px;
        text-align: left;
    }
    .custom-data-table {
        font-family: 'Sora';
        font-size: 12px;
        font-weight: 400;
        line-height: 14.4px;
        text-align: left;
        background: #565656;
    }
    .custom-data-table .v-data-table__wrapper tr:last-child {
        border-bottom: 1px solid #ddd; /* Menambahkan border bawah pada baris terakhir */
    }

    .custom-data-table .v-data-table__wrapper td,
    .custom-data-table .v-data-table__wrapper th {
        border-bottom: 1px solid #ddd; /* Menambahkan border pada setiap baris */
    }
    .pagination_custom{
        font-family: 'Sora';
        font-size: 12px;
        font-weight: 400;
        line-height: 14.4px;
        text-align: left;

    }
    .add-photo {
        display: flex;
        align-items: center; /* Aligns icon and text vertically */
        font-weight: bold; /* Make the text bold */
    }
    .btn-submit-custom{
        width: 117px; /* Fixed width */
        height: 40px; /* Fixed height */
        gap: 10px; /* Space between child elements (useful for flexbox or grid layouts) */
        border-radius: 8px 8px 8px 8px; /* Top-left corner rounded */
        font-family: 'Sora';
        font-size: 14px;
        font-weight: 700;
        line-height: 17.64px;
        text-align: left;

    }
    .input-radio-btn-custom{
        width: 292px;
        height: 40px;
        padding: 8px 8px 8px 8px; /* shorthand for padding-top: 8px; padding-right: 0; padding-bottom: 0; padding-left: 0 */
        gap: 10px;
        border-radius: 8px 8px 8px 8px; /* top-left-radius: 8px; others: 0px */
        border: 1px solid transparent; /* sets border only at the top, for other sides it is set to 0px */
        color: #1976D2;
        font-family: 'Sora';
        font-size: 14px;
        font-weight: 400;
        line-height: 16.8px;
        text-align: left;
    }
    .append-unit {
        padding-left: 5px; /* Adjust space between number and unit */
        margin-right: 10px; /* Adjust spacing from the field's right edge */
        color: rgba(0, 0, 0, 0.6); /* Styling for the appended text */
        font-size: 14px; /* Adjust to match the input font size */
        line-height: 40px; /* Ensure it vertically aligns with the text */
    }
    .txt-upload-btn{
        font-family: 'Sora';
        font-size: 14px;
        font-weight: 400;
        line-height: 16.8px;
        text-align: left;
    }
    .label-form-custom{
        font-family: 'Sora';
        font-size: 24px;
        text-align: left;

    }
    .input-btn-custom{
        /*width: 600px; !* Setting width to 600px *!*/
        /*height: 40px; !* Setting height to 40px *!*/
        gap: 14px; /* Assuming you want spacing between child elements */
    }
    .custom-data-table .v-data-table__cell {
        text-align: left; /* Ensure text alignment for all cells */
    }
    .v-data-table .v-data-table__header th {
        font-family: 'Sora', sans-serif !important; /* Set font family for header */
        font-size: 14px !important; /* Set font size for header */
        font-weight: bold !important; /* Make header text bold */
        color: #000 !important; /* Set header text color */
        text-align: left !important; /* Align text to the left */
        padding: 8px !important; /* Add padding for header cells */
    }
    .icon-custom{
        width: 36px;
        height: 27px;
        padding: 6px 11px;
        gap: 10px;
        /*border-radius: 50px 50px 50px 50px;*/
        /*border: 1px solid #D4D4D4;*/
        background: #FFFFFF;
        opacity: 1;
    }
    .show_page{
        font-family: 'Sora';
        font-size: 12px;
        font-weight: 400;
        line-height: 14.4px;
        text-align: left;
    }
    .detail-lable-custom{
        font-family: 'Sora';
        font-size: 12px;
        font-weight: 400;
        color: #565656;
    }
    .detail-value-lable-total-custom{
        font-family: 'Sora';
        color:#080808 ;
        font-size: 14px;
        font-weight: 600;
    }
    .foto-custom{
        border-radius: 8px 8px 8px 8px;
    }
    .detail-value-lable-custom{
        font-family: 'Sora';
        font-size: 14px;
        line-height: 16.8px;
        text-align: left;
        color: #080808;
        font-weight: 600;
    }
    .custom-radio {
        border: 1px solid #ccc;
        border-radius: 8px 8px 8px 8px;
        padding-left: 8px;
        padding-right: 8px;
        margin-right: 10px;
        background-color: #fff;
    }
    .label-input-custom{
        width: 68px;
        height: 15px;
        gap: 0px;
        font-family: 'Sora';
        font-size: 12px;
        font-weight: 400;
        line-height: 15px;
        text-align: left;
    }
    .custom-text-field .v-label {
        font-family: 'Sora';
        font-size: 14px;
        font-weight: 400;
        line-height: 16.8px;
        text-align: left;
    }
    .custom-text-field {
        font-family: 'Sora';
        font-size: 14px;
        font-weight: 400;
        line-height: 16.8px;
        text-align: left;
    }
    .list-detail-kertas{
        color: #565656;
    }
    .custom-text-field .v-label.v-label--active {
        color: #FF5722; /* Change color when label is active */
    }
    v-avatar {
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: #fff; /* White background for initials */
        color: #000; /* Black text color for initials */
        font-weight: bold;
    }
</style>
