// src/axios.js
import axios from 'axios';
import router from './router'; // Assuming you're using Vue Router
import url from  './url'

axios.defaults.baseURL = url +'/api/';
axios.defaults.withCredentials = true; // If your backend requires cookies or credentials

// Function to set the authorization token
const setAuthorizationToken = (token) => {
    if (token) {
        axios.defaults.headers.common['Authorization'] = `Bearer ${token}`; // Use the passed token
    } else {
        delete axios.defaults.headers.common['Authorization'];
    }
};

// Set the token on startup if it exists in local storage
const token = localStorage.getItem('token');
setAuthorizationToken(token); // Set token if available

// Add a response interceptor to handle 401 Unauthorized errors
axios.interceptors.response.use(
    (response) => {
        // Return the response if it is successful
        return response;
    },
    (error) => {
        if (error.response && error.response.status === 401) {
            // Remove token from local storage and axios headers if unauthorized
            localStorage.removeItem('token');
            setAuthorizationToken(null);

            // Redirect to login page or show a notification
            router.push({ name: 'Login' }); // Adjust according to your route setup
        }

        // Reject the promise with the error
        return Promise.reject(error);
    }
);

export { setAuthorizationToken }; // Export the function for use in your components
export default axios;
