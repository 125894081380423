<template>
    <v-container class="mt-3">
        <!-- Header Section -->
        <v-row justify="space-between" align="center">

            <v-col cols="8">
                 <!-- Left arrow icon -->
                <h2 class="font-weight-bold"><v-icon @click="back" left>mdi-arrow-left</v-icon> Stock Detail</h2>
            </v-col>
            <v-col cols="4" class="text-right">
                <v-btn color="primary" class="btn-top-table" @click="downloadPdf" elevation="0">
                    <v-icon left>mdi-printer</v-icon>
                    PRINT
                </v-btn>
            </v-col>
        </v-row>

        <!-- Main Detail Section -->
        <v-card class="pa-4 my-4" outlined elevation="0">
            <v-row>
                <v-col cols="4">
                    <p class="detail-lable-custom">Tanggal Stock:</p>
                    <p class="detail-value-lable-custom mt-n2">{{ formatDate(data[0]?.start_date) }}</p>
                </v-col>
                <v-col cols="4">
                    <p class="detail-lable-custom">Batch ID:</p>
                    <p class="detail-value-lable-custom mt-n2">{{ data[0]?.order_code }}</p>
                </v-col>
                <v-col cols="4">
                    <p class="detail-lable-custom">Batch Name:</p>
                    <p class="detail-value-lable-custom mt-n2">{{ data[0]?.name_batch }}</p>
                </v-col>
            </v-row>

            <v-divider></v-divider>

            <v-row class="mt-4">
                <v-col cols="6">
                    <v-card outlined class="pa-4 light-card" elevation="0">
                        <p class="detail-lable-custom">Jumlah Kartu:</p>
                        <p class="detail-value-lable-total-custom mt-n3">{{ total }} pcs</p>
                    </v-card>
                </v-col>
                <v-col cols="6">
                    <v-card outlined class="pa-4 light-card" elevation="0">
                        <p class="detail-lable-custom">Jumlah Fee:</p>
                        <p class="detail-value-lable-total-custom mt-n3">{{ fee }}</p>
                    </v-card>
                </v-col>
            </v-row>
        </v-card>

        <!-- Batch List Section -->
        <v-card class="pa-4 mt-4" outlined elevation="0">
            <h3 class=" list-detail-kertas">Batch List</h3>
            <v-divider class="mb-4"></v-divider>
            <v-row v-for="(item, index) in data" :key="index" class="my-2">
                <v-col cols="12">
                    <v-card class="pa-4 mb-3" outlined elevation="0">
                        <v-card-text>
                            <v-row>
                                <v-col cols="4">
                                    <p class="detail-lable-custom">Nama:</p>
                                    <p class="detail-value-lable-custom mt-n2">{{ item.nama }}</p>
                                </v-col>
                                <v-col cols="4">
                                    <p class="detail-lable-custom">No. Rekening:</p>
                                    <p class="detail-value-lable-custom mt-n2">{{ item.no_rekening }}</p>
                                </v-col>
                                <v-col cols="4">
                                    <p class="detail-lable-custom">Bank:</p>
                                    <p class="detail-value-lable-custom mt-n2">{{ item.bank }}</p>
                                </v-col>
                            </v-row>
                            <v-divider></v-divider>
                            <v-row class="mt-2">
                                <v-col cols="4">
                                    <p class="detail-lable-custom">Kartu Hitam:</p>
                                    <p class="detail-value-lable-custom mt-n2">{{ item.jmlh_hitam }} pcs</p>
                                </v-col>
                                <v-col cols="4">
                                    <p class="detail-lable-custom">Kartu Silver:</p>
                                    <p class="detail-value-lable-custom mt-n2">{{ item.jmlh_silver }} pcs</p>
                                </v-col>
                            </v-row>
                        </v-card-text>
                    </v-card>
                </v-col>
            </v-row>
        </v-card>
    </v-container>
</template>

<script>
    import axios from 'axios';

    export default {
        data() {
            return {
                data: [], // Holds the data from the endpoint
                total: "", // Total kartu hitam dan silver
                fee: "" // Fee from the endpoint
            };
        },
        methods: {
            back() {
                this.$router.push({ name: 'kertas' });
            },
            // Fetching data from the endpoint
            fetchData() {
                const params = {
                    id: this.$route.params.id,
                };
                axios.post('/waranty_card/detail', params).then(response => {
                    this.data = response.data.data;
                    this.total = response.data.total;
                    this.fee = response.data.fee;
                }).catch(error => {
                    console.log(error);
                });
            },
            // Formatting date
            formatDate(date) {
                const options = { year: 'numeric', month: 'short', day: 'numeric' };
                return new Date(date).toLocaleDateString('en-GB', options);
            },
            // Print page function
            printPage() {
                window.print();
            },
            async downloadPdf() {
                this.$router.push({ path: `/PrintDetailkertas/${this.$route.params.id}` });

                // try {
                //     // Define your parameters if needed
                //     const params = {
                //         id: this.$route.params.id, // Example of using route params
                //     };
                //
                //     // Send a POST request to download the PDF
                //     const response = await axios.post('/waranty_card/pdf/detail', params, {
                //         responseType: 'blob', // Important for binary data
                //     });
                //
                //     // Create a Blob from the response
                //     const pdfBlob = new Blob([response.data], { type: 'application/pdf' });
                //     const pdfUrl = URL.createObjectURL(pdfBlob);
                //
                //     // Create a link to trigger the download
                //     const link = document.createElement('a');
                //     link.href = pdfUrl;
                //     link.setAttribute('download', 'Invoice.pdf'); // Set the desired file name
                //     document.body.appendChild(link);
                //     link.click();
                //
                //     // Clean up and remove the link
                //     link.parentNode.removeChild(link);
                //     URL.revokeObjectURL(pdfUrl);
                // } catch (error) {
                //     console.error('Error downloading PDF:', error);
                // }
            },
        },
        mounted() {
            this.fetchData();
        }
    };
</script>

<style scoped>
    .text-right {
        text-align: right;
    }

    .light-card {
        background-color: #F6F6F6;
    }

    .caption {
        color: #6c757d;
        margin-bottom: 0;
    }

    .headline {
        color: #343a40;
    }

    .font-weight-medium {
        font-weight: 500;
    }
</style>
