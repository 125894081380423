<template>
    <v-container class="px-5 py-3">
        <v-row class="align-center mb-n4">
            <v-col cols="auto">
                <v-icon @click="back" left>mdi-arrow-left</v-icon> <!-- Left arrow icon -->
            </v-col>
            <v-col class="ml-n5 label-form-custom">
                <h2 class="mb-0">{{LabelForm}}</h2>
            </v-col>
        </v-row>
        <v-card class="mt-5 custom-rounded-card pa-4">
            <v-form ref="form" v-model="valid" lazy-validation> <!-- Bind form validity to 'valid' -->
                <!-- Readonly Stock ID-->
                <v-row class="mb-n10" v-if="this.$route.params.id">
                    <v-col cols="12 mb-n4">
                        <span>Stock ID</span>
                    </v-col>
                    <v-col cols="12">
                        <v-text-field v-model="stockid" label="Stock ID" readonly
                                      class="small-text-field "  solo dense single-line>
                        </v-text-field>
                    </v-col>

                </v-row>

                <!-- Type Stock Selector with Validation -->
                <v-row >
                    <v-col cols="12 mb-n12 label-input-custom">
                        <span>Type Stock</span>
                    </v-col>
                    <v-col cols="12" sm="8" class="mb-n13  ">
                        <v-radio-group v-model="selectedType" :rules="[v => !!v || 'Type Stock is required']"  row>
                                <v-card
                                        style="box-shadow: none !important;"
                                        class="mx-2 my-1 input-radio-btn-custom "
                                        :elevation="selectedType === 'Masuk' ? 1 : 1"
                                        outlined
                                        @click="selectedType = 'Masuk'"
                                        :class="selectedType === 'Masuk' ? 'radio-selected' : ''"
                                >
                                    <v-row align="center">
                                        <v-col class="d-flex align-center justify-center" cols="2">
                                            <v-icon color="primary" v-if="selectedType === 'Masuk'">mdi-check-circle</v-icon>
                                            <v-icon color="grey" v-else>mdi-circle-outline</v-icon>
                                        </v-col>
                                        <v-col cols="10">
                                            <span class="custom-text-field ">Masuk</span>
                                        </v-col>
                                    </v-row>
                                </v-card>

                                <v-card
                                        style="box-shadow: none !important;"
                                        class="mx-2 my-1 input-radio-btn-custom ml-2"
                                        :elevation="selectedType === 'Keluar' ? 5 : 1"
                                        outlined
                                        @click="selectedType = 'Keluar'"
                                        :class="selectedType === 'Keluar' ? 'radio-selected' : ''"
                                >
                                    <v-row no-gutters align="center">
                                        <v-col class="d-flex align-center justify-center" cols="2">
                                            <v-icon color="primary" v-if="selectedType === 'Keluar'">mdi-check-circle</v-icon>
                                            <v-icon color="grey" v-else>mdi-circle-outline</v-icon>
                                        </v-col>
                                        <v-col cols="10">
                                            <span>Keluar</span>
                                        </v-col>
                                    </v-row>
                                </v-card>
                        </v-radio-group>
                    </v-col>
                </v-row>

                <!-- Date Picker with Validation -->
                <v-row class="mb-4 mt-12">
                    <v-col cols="12  label-input-custom">
                        <span>Tanggal Stock</span>
                    </v-col>
                    <v-col cols="12">
                        <v-menu
                                ref="menu"
                                v-model="menu"
                                :close-on-content-click="false"
                                :nudge-right="40"
                                transition="scale-transition"
                                offset-y
                                min-width="290px"
                        >
                            <template v-slot:activator="{ on, attrs }">
                                <v-text-field
                                        v-model="date"
                                        label="Tanggal Stock"
                                        readonly
                                        :rules="[v => !!v || 'Tanggal Stock is required']"
                                        class="small-text-field input-btn-custom custom-text-field custom-border"
                                        solo
                                        dense
                                        single-line
                                        v-bind="attrs"
                                        v-on="on"
                                >
                                    <template v-slot:prepend-inner>
                                        <v-img
                                                @click="menu = !menu"
                                                :src=icon_calendar
                                        ></v-img>
                                    </template>
                                </v-text-field>
                            </template>
                            <v-date-picker v-model="date" @input="menu = false"></v-date-picker>
                        </v-menu>
                    </v-col>
                </v-row>

                <!-- Source/Destination Selector with Validation -->
                <v-row class="mb-4 mt-n8">
                    <v-col cols="12 mb-n5 label-input-custom">
                        <span>Source/Destination</span>
                    </v-col>
                    <v-col cols="12 mt-4">
                        <v-select v-model="selectedSource" :items="['Indonesia', 'China', 'Warehouse Tangerang', 'Wholesale (FJB)', 'Retail (GAP)','Selisih']"
                                  label="Select source/destination" :rules="[v => !!v || 'Source/Destination is required']"
                                  class="small-text-field input-btn-custom custom-text-field custom-border"
                                  solo dense  single-line >
                        </v-select>
                    </v-col>
                </v-row>

                <!-- Numeric Inputs with Validation -->
                <v-row class="mb-3 mt-n8">
                    <v-col cols="12 label-input-custom">
                        <span>Kartu Hitam</span>
                    </v-col>
                    <v-col cols="12 mt-n1">
                        <v-text-field v-model="kartuHitam" label="Kartu Hitam" type="number" :rules="[v => (v !== null && v !== '' && v >= 0) || 'Kartu Hitam must be a positive number']"
                                      class="small-text-field input-btn-custom custom-text-field custom-border"  solo dense single-line >
                            <template v-slot:append-outer>
                                <span style="margin-left: 5px;">pcs</span>
                            </template>
                        </v-text-field>
                    </v-col>
                    <v-col cols="12 mt-n4 label-input-custom">
                        <span>Kartu Silver</span>
                    </v-col>
                    <v-col cols="12">
                        <v-text-field v-model="kartuSilver" label="Kartu Silver" type="number" :rules="[v => (v !== null && v !== '' && v >= 0) || 'Kartu Silver must be a positive number']"
                                      class="small-text-field input-btn-custom custom-text-field custom-border" solo dense single-line >
                            <template v-slot:append-outer>
                                <span style="margin-left: 5px;">pcs</span>
                            </template>
                        </v-text-field>
                    </v-col>
                </v-row>

<!--                <v-row class="mb-4 mt-n4">-->
<!--                    <v-col cols="12 label-input-custom">-->
<!--                        <span >Bukti Photo</span>-->
<!--                    </v-col>-->
<!--                </v-row>-->
<!--                <v-row v-for="(fileInputs, index) in fileInputs" :key="index" class="mb-4 mt-n8">-->
<!--                    <v-col cols="11">-->
<!--                        <v-file-input-->
<!--                                label="Bukti Photo"-->
<!--                                prepend-icon="mdi-camera"-->
<!--                                class="small-text-field mt-3 input-btn-custom"-->
<!--                                solo-->
<!--                                dense-->
<!--                                hide-details-->
<!--                                single-line-->
<!--                                multiple-->
<!--                                v-model="fileInputs.files"-->
<!--                                @change="updatePreview(fileInputs.files, index)"-->
<!--                                :rules="[v => fileInputs.files.length > 0 || 'At least one file must be uploaded']"-->
<!--                        ></v-file-input>-->
<!--                    </v-col>-->
<!--                    <v-col cols="1" class="d-flex align-center mt-3 ml-n5">-->
<!--                        <v-btn icon @click="removeFile(index)">-->
<!--                            <v-icon>mdi-delete</v-icon>-->
<!--                        </v-btn>-->
<!--                    </v-col>-->

<!--                </v-row>-->
<!--                <v-btn color="primary" @click="addFileInput">Add Another Photo</v-btn>-->
                <v-container class="ml-n3 mt-n8">
                    <v-row class="mb-4">
                        <v-col cols="12" class="label-input-custom">
                            <span>Bukti Photo</span>
                        </v-col>
                    </v-row>

                    <!-- Dynamic file inputs -->
                    <v-row v-for="(fileInputs, index) in fileInputs" :key="index" class="mb-2 mt-n8 align-center">
                        <v-col cols="11">
                            <v-file-input
                                    label="Input file"
                                    prepend-icon=""
                                    class="small-text-field mt-3 input-btn-custom txt-upload-btn custom-text-field custom-border"
                                    solo
                                    dense
                                    :hide-details="false"
                            single-line
                            multiple
                            v-model="fileInputs.files"
                            @change="updatePreview(fileInputs.files, index)"
                            :rules="[v => v.length > 0 || 'At least one file must be uploaded']"
                            >
                            <template v-slot:prepend-inner>
                                <v-img
                                        :src=icon_upload
                                        contain
                                ></v-img>
                            </template>
                            </v-file-input>
                        </v-col>
                        <v-col  class="d-flex align-center mt-n3 ml-n3"> <!-- Adjusted column width for close button -->
                            <v-btn icon @click="removeFile(index)">
                                <v-img
                                        :src=icon_close_upload
                                ></v-img>
                            </v-btn>
                        </v-col>
                    </v-row>

                    <span class="add-photo " @click="addFileInput" style="cursor: pointer; color: #1976d2;">
                      + Add Another Photo
                    </span>
                </v-container>

                <!-- Submit Button -->
                <v-row justify="end">
                    <v-col cols="auto">
                        <v-btn color="primary" class="btn-submit-custom" depressed @click="checkFormValidity">Submit</v-btn>
                    </v-col>
                </v-row>
            </v-form>
        </v-card>
        <!-- Confirmation Dialog -->
        <v-dialog v-model="confirmationDialog" max-width="400">
            <v-card class="elevation-12 rounded-lg">
                <v-card-title class="headline d-flex justify-center pb-2">
                    <v-icon color="primary" class="mr-2">mdi-alert-circle</v-icon>
                    Confirm Submission
                </v-card-title>

                <v-divider></v-divider> <!-- Divider for a cleaner separation -->

                <v-card-text class="text-center py-4">
                    {{confirmationDialogMessage}}
                </v-card-text>

                <v-card-actions class="d-flex justify-end px-4 pb-4">
                    <v-btn
                            color="grey lighten-1"
                            text
                            class="mr-4"
                            @click="confirmationDialog = false"
                    >
                        <v-icon left>mdi-cancel</v-icon>Cancel
                    </v-btn>
                    <v-btn
                            color="primary"
                            @click="submitForm"
                    >
                        <v-icon left>mdi-check-circle</v-icon>Yes, Submit
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <v-snackbar
                v-model="successSnackbar"
                :color="color_snackbar"
                top
                timeout="3000"
        >
            {{pesan_snackbar}}
            <v-btn text @click="successSnackbar = false">Close</v-btn>
        </v-snackbar>

    </v-container>
</template>

<script>
    import axios from 'axios';
    import url from "../../url";
    export default {
        data () {
            return {
                icon_upload: `${url}/images/attach-circle.png`,
                icon_close_upload: `${url}/images/x.png`,
                icon_calendar: `${url}/images/calendar.png`,
                date: "",
                menu: false,
                valid: false, // Tracks form validity
                selectedType: null, // Store the selected radio button value
                fileInputs: [{ files: [], previews: [] }], // Initialize with one file input and previews
                selectedSource: null, // Store the selected source/destination
                kartuHitam: null, // Store Kartu Hitam value
                stockid: null, // Store Kartu Hitam value
                kartuSilver: null, // Store Kartu Silver value
                successSnackbar: false,
                color_snackbar: '',
                pesan_snackbar: '',
                confirmationDialog: false,
                confirmationDialogMessage: 'Apakah Anda yakin ingin menyimpan data?',
                LabelForm: 'Add New Stock',
            }
        },
        methods: {
            back() {
                this.$router.push({ name: 'delivery' }); // Replace 'add-stock' with your route name
            },
            updatePreview(files, index) {
                if (files && Array.isArray(files)) {
                    const fileReaders = files.map((file) => {
                        return new Promise((resolve) => {
                            const reader = new FileReader();
                            reader.onload = (e) => {
                                resolve(e.target.result);
                            };
                            reader.readAsDataURL(file);
                        });
                    });

                    Promise.all(fileReaders).then((results) => {
                        this.$set(this.fileInputs[index], 'previews', results);
                    });
                }
            },
            removeFile(fileIndex) {
                this.fileInputs = this.fileInputs.filter((_, index) => index !== fileIndex);
            },
            addFileInput() {
                this.fileInputs.push({ files: [], previews: [] }); // Add a new file input
            },
            checkFormValidity() {
                // Check if form is valid
                this.$refs.form.validate(); // Triggers validation for all fields

                if (this.$refs.form.validate()) {
                    // Show confirmation dialog if form is valid
                    this.confirmationDialog = true;
                    if (this.$route.params.id) {
                        this.confirmationDialogMessage = 'Apakah Anda yakin ingin mengupdate data?'
                    }
                } else {
                    console.log('Form validation failed');
                }
            },

            async fetchStockData(id) {
                try {
                    const params = {
                        id: id,
                    };
                    const response = await axios.post('stock/list', params); // Replace with your actual API endpoint
                    const { data } = response.data;  // Ensure you capture total from response
                    if (data.length == 1){
                        this.stockid = data[0].order_code
                        this.selectedType = data[0].type_stock
                        this.date = data[0].tanggal
                        this.selectedSource = data[0].source_destination
                        this.kartuHitam = this.formattedStock(data[0].jmlh_hitam)
                        this.kartuSilver = this.formattedStock(data[0].jmlh_silver)
                        const filePathString = data[0].file_path;
                        this.populateFileInputs(filePathString); // Populate fileInputs on component mount
                    }

                } catch (error) {
                    console.error('Error fetching stock data:', error);
                }
            },
            formattedStock(id) {
                return id.replace(/\s*pcs/g, '').trim(); // Removes "pcs"
            },
            async submitForm() {
                this.confirmationDialog = false; // Close dialog

                const formData = new FormData();

                // Append form data
                formData.append('id', this.$route.params.id);
                formData.append('type_stock', this.selectedType);
                formData.append('tanggal', this.date);
                formData.append('source_destination', this.selectedSource);
                formData.append('jmlh_hitam', this.kartuHitam);
                formData.append('jmlh_silver', this.kartuSilver);

                // Append files
                this.fileInputs.forEach(input => {
                    input.files.forEach(file => {
                        formData.append('file_path[]', file); // Use 'photos[]' to handle multiple files
                    });
                });
                try {
                    const url = this.$route.params.id ? '/stock/update' : '/stock/created'; // Define URL based on ID presence

                    const response = await axios.post(url, formData, {
                        headers: {
                            'Content-Type': 'multipart/form-data'
                        }
                    });
                    if (response.data.error == false) {
                        this.successSnackbar = true;
                        this.color_snackbar = 'success';
                        if (this.$route.params.id){
                            this.pesan_snackbar = response.data.message;
                        }else{
                            this.pesan_snackbar = response.data.message;
                        }
                        setTimeout(() => {
                            this.$router.push({ name: 'delivery' });
                        }, 1000); // Simulate network delay
                    }else{
                        this.successSnackbar = true;
                        this.color_snackbar = 'red';
                        if (this.$route.params.id){
                            this.pesan_snackbar = response.data.message;
                        }else{
                            this.pesan_snackbar = response.data.message;
                        }
                    }
                } catch (error) {
                    console.error('Error:', error);
                }
            },
            populateFileInputs(filePathString) {
                const filePaths = JSON.parse(filePathString.replace(/\\/g, '')); // Convert string to array
                // Remove "image/" prefix
                const cleanedPaths = filePaths.map(filePath => filePath.replace(/^image\//, ''));
                this.fileInputs = cleanedPaths.map(cleanedPath => ({
                    files: [new File([], cleanedPath)], // Create a File object for each cleaned path
                    previews: [cleanedPath] // Add cleaned path to previews
                }));
            },
        },
        mounted() {
            const id = this.$route.params.id;
            if (id){
                this.LabelForm = 'Update Stock'
                this.fetchStockData(id)
            }
        },
    }
</script>

<style scoped>
    /* Custom styles for radio button */
    .v-input--radio {
        border-radius: 50%;
    }

    .v-input--radio input:checked + .v-input__control .v-input__slot {
        border-color: #1976d2; /* Change the border color when checked */
    }

    .v-input--radio input:checked + .v-input__control .v-input__slot::after {
        content: '✓'; /* Show a check mark */
        font-size: 14px; /* Adjust size as needed */
        color: #fff; /* Check mark color */
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }
    .small-text-field {
        border-radius: 8px;
    }

    .small-text-field:focus {
        box-shadow: none; /* Remove shadow on focus */
    }
</style>
