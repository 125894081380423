import Vue from 'vue';
import VueRouter from 'vue-router';
import Login from '../components/Login.vue';
import Register from '../components/Register.vue';
import Dashboard from '../views/Dashboard.vue'; // Assuming you have a Dashboard.vue in /views folder
import Delivery from '../views/Delivery/Delivery.vue'; // Assuming you have a Dashboard.vue in /views folder
import FromDeliver from '../views/Delivery/FromDeliver'; // Assuming you have a Dashboard.vue in /views folder
import DetailDeliver from '../views/Delivery/DetailDeliver'; // Assuming you have a Dashboard.vue in /views folder
import Kertas from '../views/Kertas/Kertas'; // Assuming you have a Dashboard.vue in /views folder
import FromKertas from '../views/Kertas/FromKertas'; // Assuming you have a Dashboard.vue in /views folder
import DetailKertas from '../views/Kertas/DetailKertas'; // Assuming you have a Dashboard.vue in /views folder
import PrintDetailkertas from '../views/Kertas/PrintDetailkertas'; // Assuming you have a Dashboard.vue in /views folder

Vue.use(VueRouter);

const routes = [
  { path: '/', name: 'login',component: Login },
  { path: '/register', name: 'register',component: Register },
  { path: '/dashboard', name: 'dashboard',component: Dashboard,meta: { requiresAuth: true } },
  { path: '/delivery', name: 'delivery',component: Delivery,meta: { requiresAuth: true } },
  { path: '/AddDelivery', name: 'AddDelivery',component: FromDeliver,meta: { requiresAuth: true } },
  { path: '/UpdateDelivery/:id', name: 'UpdateDelivery',component: FromDeliver,meta: { requiresAuth: true } },
  { path: '/DetailDelivery/:id', name: 'DetailDeliver',component: DetailDeliver,meta: { requiresAuth: true } },
  { path: '/kertas', name: 'kertas',component: Kertas,meta: { requiresAuth: true } },
  { path: '/Addkertas', name: 'Addkertas',component: FromKertas,meta: { requiresAuth: true } },
  { path: '/Updatekertas/:id', name: 'Updatekertas',component: FromKertas,meta: { requiresAuth: true } },
  { path: '/Detailkertas/:id', name: 'Detailkertas',component: DetailKertas,meta: { requiresAuth: true } },
  { path: '/PrintDetailkertas/:id', name: 'PrintDetailkertas',component: PrintDetailkertas,meta: { requiresAuth: true } },

];

const router = new VueRouter({
  mode: 'history',
  routes,
});


router.beforeEach((to, from, next) => {
  const isAuthenticated = !!localStorage.getItem('token'); // Check if token exists in localStorage

  // Redirect to login page if not authenticated and trying to access a protected route
  if (to.matched.some(record => record.meta.requiresAuth) && !isAuthenticated) {
    if (to.path !== '/') {
      next('/'); // Redirect to login page
    } else {
      next(false); // Prevent navigation if already on the login page
    }
  }
  // Prevent authenticated users from accessing the login page
  else if (isAuthenticated && to.name === 'login') {
    next('/dashboard'); // Redirect to dashboard
  }
  // Allow access to other routes
  else {
    next(); // Continue to the requested route
  }
});



export default router
