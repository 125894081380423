<template>
    <div :class="chartContainerClass">
        <apexchart type="bar" :options="chartOptions" :series="chartSeries" />
    </div>
</template>

<script>
    import VueApexCharts from 'vue3-apexcharts';
    import axios from '/src/axios';

    export default {
        components: {
            apexchart: VueApexCharts,
        },
        data() {
            return {
                chartOptions: {
                    chart: {
                        type: 'bar',
                        toolbar: {
                            show: false, // Hide toolbar for cleaner look
                        },
                    },
                    xaxis: {
                        categories: [], // Populated from API (names)
                        labels: {
                            style: {
                                fontSize: '12px', // Adjust font size for readability
                                fontFamily: 'Sora', // Set custom font family
                                color: '#3867D3'
                            },
                        },
                    },
                    plotOptions: {
                        bar: {
                            horizontal: false,
                            endingShape: 'rounded', // Add a more modern feel to the bars
                            columnWidth: '90%', // Adjust width for better spacing
                            borderRadius: 10,
                        },
                    },
                    dataLabels: {
                        enabled: false, // Disable data labels for cleaner look
                    },
                    tooltip: {
                        enabled: true,
                        style: {
                            fontSize: '12px', // Adjust tooltip font size
                            fontFamily: 'Sora', // Set custom font family
                            color: '#3867D3'
                        },
                    },
                    legend: {
                        position: 'top', // Move legend to top for better visibility
                        horizontalAlign: 'center',
                        fontSize: '12px', // Make legend text larger
                        fontFamily: 'Sora', // Set custom font family
                        color: '#3867D3'
                    },
                    responsive: [
                        {
                            breakpoint: 600, // Mobile screens
                            options: {
                                chart: {
                                    height: 250, // Smaller chart height for mobile
                                },
                                xaxis: {
                                    labels: {
                                        style: {
                                            fontSize: '10px', // Smaller labels for mobile
                                            fontFamily: 'Sora',
                                            color: '#3867D3'
                                        },
                                    },
                                },
                            },
                        },
                        {
                            breakpoint: 1024, // Tablet screens
                            options: {
                                chart: {
                                    height: 300, // Medium height for tablet
                                },
                            },
                        },
                    ],
                },
                chartSeries: [
                    {
                        name: 'Total Kartu',
                        data: [],
                    },
                ],
            };
        },
        computed: {
            chartContainerClass() {
                return window.innerWidth <= 600 ? 'chart-container' : 'chart-container-mobile';
            },
        },
        beforeDestroy() {
            window.removeEventListener('resize', this.updateChartClass);
        },
        mounted() {
            this.fetchChartData();
            window.addEventListener('resize', this.updateChartClass);
        },
        methods: {
            updateChartClass() {
                this.chartContainerClass = window.innerWidth <= 600 ? 'chart-container-mobile' : 'chart-container';
            },
            async fetchChartData() {
                try {
                    const response = await axios.post('dashboard');
                    const kartuTertinggi = response.data.Kartu_tertinggi;

                    if (Array.isArray(kartuTertinggi) && kartuTertinggi.length > 0) {
                        const categories = kartuTertinggi.map(item => item.nama);
                        const totalKartuData = kartuTertinggi.map(item => item.total_kartu);

                        this.chartOptions = {
                            ...this.chartOptions,
                            xaxis: {
                                ...this.chartOptions.xaxis,
                                categories: categories,
                            },
                        };

                        this.chartSeries = [
                            {
                                name: 'Total Kartu',
                                data: totalKartuData,
                            }
                        ];
                    } else {
                        console.warn('Kartu_tertinggi is empty or not an array');
                    }
                } catch (error) {
                    console.error('Error fetching chart data:', error);
                }
            },
        },
    };
</script>

<style scoped>
    .chart-container-mobile {
        padding: 5px;
        height: 250px; /* Smaller height for mobile */
    }
    .chart-container {
        padding: 10px; /* Add padding for better spacing */
        max-width: 100%;
        display: flex;
        justify-content: center;
    }

    @media screen and (max-width: 600px) {
        .chart-container {
            /*height: 250px; !* Adjust height for mobile screens *!*/
        }
    }

    @media screen and (min-width: 601px) and (max-width: 1024px) {
        .chart-container {
            /*height: 300px; !* Adjust height for tablet screens *!*/
        }
    }

    @media screen and (min-width: 1025px) {
        .chart-container {
            /*height: 400px; !* Adjust height for larger screens *!*/
        }
    }
</style>
