<template>
    <v-container>
        <!-- Welcome message -->
        <v-row>
            <v-col cols="12" class="title-dashboard-custom">
                <h3>Halo, {{name}}</h3>
                <h3>Selamat Datang di Aplikasi Casio Stock Management</h3>
            </v-col>
        </v-row>

        <!-- Summary cards -->
        <v-row>
            <v-col cols="3">
                <v-card class="pa-4 d-flex justify-space-between align-center" outlined>
                    <div>
                        <h4 class="title-kartu-custom">Total Kartu Hitam</h4>
                        <h2 class="detail-title-kartu-custom">{{ total_kartu_hitam }}</h2>
                    </div>
                    <v-avatar size="40" class="blue lighten-5 d-none d-sm-flex">
                        <v-img
                                class="icon-custom"
                                :src=icon_dompet
                                contain
                                height="40"
                        ></v-img>
                    </v-avatar>
                </v-card>
            </v-col>

            <v-col cols="3">
                <v-card class="pa-4 d-flex justify-space-between align-center" outlined>
                    <div>
                        <h4 class="title-kartu-custom">Total Kartu Silver</h4>
                        <h2 class="detail-title-kartu-custom">{{ total_kartu_silver }}</h2>
                    </div>
                    <v-avatar size="40" class="blue lighten-5 d-none d-sm-flex">
                        <v-img
                                class="icon-custom"
                                :src=icon_dompet
                                contain
                                height="40"
                        ></v-img>
                    </v-avatar>
                </v-card>
            </v-col>

            <v-col cols="3">
                <v-card class="pa-4 d-flex justify-space-between align-center" outlined>
                    <div>
                        <h4 class="title-kartu-custom">Total Fee</h4>
                        <h2 class="detail-title-kartu-custom">{{ total_fee }}</h2>
                    </div>
                    <v-avatar size="40" class="blue lighten-5 d-none d-sm-flex">
                        <v-img
                                class="icon-custom"
                                :src=icon_total_fee
                                contain
                                height="40"
                        ></v-img>
                    </v-avatar>
                </v-card>
            </v-col>

            <v-col cols="3">
                <v-card class="pa-4 d-flex justify-space-between align-center" outlined>
                    <div>
                        <h4 class="title-kartu-custom">Total Batch</h4>
                        <h2 class="detail-title-kartu-custom">{{ total_batch }}</h2>
                    </div>
                    <v-avatar size="40" class="blue lighten-5 d-none d-sm-flex">
                        <v-img
                                class="icon-custom"
                                :src=icon_total_batch
                                contain
                                height="40"
                        ></v-img>
                    </v-avatar>
                </v-card>
            </v-col>
        </v-row>

        <!-- Charts section -->
        <v-row>
            <v-col cols="6">
                <v-card class="pa-4" outlined>
                    <h4 class="title-chart-custom">Top 5 User dengan jumlah kartu tertinggi</h4>
                    <!-- Bar chart for high card users -->
                    <BarChartKartuTertinggi :chartData="highCardUsers" />
                </v-card>
            </v-col>

            <v-col cols="6">
                <v-card class="pa-4" outlined>
                    <h4 class="title-chart-custom">Top 5 User dengan jumlah kartu terendah</h4>
                    <!-- Bar chart for low card users -->
                    <BarChartKartuTerendah :chartData="lowCardUsers" />
                </v-card>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
    import BarChartKartuTertinggi  from '../components/BarChart/BarChartKartuTertinggi.vue';
    import BarChartKartuTerendah  from '../components/BarChart/BarChartKartuTerendah.vue';
    import axios from "../axios";
    import url from "../url"; // Adjust path as needed

    export default {
        components: {
            BarChartKartuTertinggi,
            BarChartKartuTerendah,
        },
        data() {
            return {
                icon_dompet: `${url}/images/icon_dompet.png`,
                icon_total_fee: `${url}/images/total_fee.png`,
                icon_total_batch: `${url}/images/total_batch.png`,
                name:localStorage.getItem('name'),
                total_kartu_hitam:'',
                total_kartu_silver:'',
                total_fee:'',
                total_batch:'',
                highCardUsers: {
                    labels: [],
                    datasets: [
                        {
                            label: 'Jumlah Kartu',
                            backgroundColor: '#3b82f6',
                            data: [],
                        },
                    ],
                },
                lowCardUsers: {
                    labels: [],
                    datasets: [
                        {
                            label: 'Jumlah Kartu',
                            backgroundColor: '#60a5fa',
                            data: [],
                        },
                    ],
                },
            };
        },

        methods: {
            async fetchChartData() {
                try {
                    const response = await axios.post('dashboard');
                    this.total_kartu_hitam = response.data.total_kartu[0].kartu_hitam
                    this.total_kartu_silver = response.data.total_kartu[0].kartu_silver
                    this.total_fee = response.data.total_kartu_kertas[0].fee
                    this.total_batch = response.data.totalBatch
                    // const kartuTertinggi = response.data.Kartu_tertinggi;
                    // const kartuTerendah = response.data.Kartu_terendah;
                    //
                    // // Update highCardUsers chart data
                    // this.highCardUsers.labels = kartuTertinggi.map(user => user.name); // assuming response has user.name
                    // this.highCardUsers.datasets[0].data = kartuTertinggi.map(user => user.cards); // assuming response has user.cards
                    //
                    // // Update lowCardUsers chart data
                    // this.lowCardUsers.labels = kartuTerendah.map(user => user.name); // assuming response has user.name
                    // this.lowCardUsers.datasets[0].data = kartuTerendah.map(user => user.cards); // assuming response has user.cards

                } catch (error) {
                    console.error('Error fetching chart data:', error);
                }
            }
        },

        // Fetch the data when the component is created
        created() {
            localStorage.setItem('menu_title', "Dashboard");
            this.fetchChartData();
        },
    };
</script>

<style scoped>
    h2 {
        font-weight: bold;
    }

    h4 {
        margin-bottom: 16px;
    }
</style>
