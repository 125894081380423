import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';
import 'vuetify/dist/vuetify.min.css';

Vue.use(Vuetify);

export default new Vuetify({
    typography: {
        fontFamily: 'Geomanist', // Set Geomanist as default font
    },
});
